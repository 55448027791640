<template>
  <ModularCardsGrid v-if="layout === 'grid'" v-bind="propsWithoutLayout" />
  <ModularCardsCarousel v-else v-bind="propsWithoutLayout" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type ModularCardsCarouselProps } from '@backmarket/http-api/src/api-specs-content/models/modular-card'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import ModularCardsCarousel from './ModularCardsCarousel.vue'
import ModularCardsGrid from './ModularCardsGrid.vue'

const props = withDefaults(
  defineProps<ModularCardsCarouselProps & ContentBlockProps>(),
  {
    cardsHavePadding: true,
    layout: 'carousel',
  },
)

const propsWithoutLayout = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { layout, ...rest } = props

  return rest
})
</script>
